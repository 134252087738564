@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard-dynamic-subset.css");

@layer base {
    html {
        font-family: pretendard, system-ui, sans-serif;
    }

}
/* 레이아웃 */
.wrapper{
    @apply flex flex-col grow min-h-screen w-full overflow-hidden
}
.header{
    @apply flex justify-center text-xl px-4 py-3 items-center bg-white
}

/* 사이드 바 */
.sidebar_container{
    @apply w-[500px] space-y-3 grow pt-3 px-3 bg-neutral-100
}

.sidebar_title{
    @apply text-lg font-bold text-dark-grey
}

.sidebar_box{
    @apply bg-white rounded-xl py-3 px-4 space-y-3
}

.sidebar_tab{
    @apply flex items-center justify-center absolute z-10 [writing-mode:vertical-lr] w-14 h-32 pl-2 py-3 text-base font-extrabold rounded-tr-lg rounded-br-lg left-[465px] cursor-pointer
}

/* 달력 */
.calendar_wrapper {
    @apply flex justify-center items-center mb-[10px]
}

